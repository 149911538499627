.cart {
  display: flex;
  position: sticky;
  flex-direction: column;
  background-color: #ffffff;
  z-index: 1;
  top: 0;
  height: 100%;
  max-height: calc(100vh - 60px);
  transition: 0.3s;
  &__isSticky {
    max-height: 100vh;
    .cartItemsInner {
      transition: 0.3s;
    }
  }
  &__wrapper {
    background: #ffffff;
    box-shadow: 0 0 60px rgba(26, 20, 63, 0.1);
    z-index: 2;
  }
}

.cartTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  //padding: 40px 0;
  border-bottom: 1px solid #d9d9d9;

  span {
    font-style: normal;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000000;
  }
}

.cartItemsWrapper {
  flex: 1;
  display: flex;
}

.cartItemsInner {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  flex: 1 1;
}

.comboCartItemsWrapper {
  display: flex;
  // justify-content: space-between;
}
.comboCartItemsInfoWrapper {
  width: 87%;
}

.comboCartItemsHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5em;
}

.comboName {
  font-size: 1.1rem;
  margin-bottom: 0rem;
  margin-right: 0.5em;
}

.cartItemsDelete {
  display: block;
  background: #f2f2f2;
  height: 100%;
  border-radius: 3px 0 0 3px;
  border: none;
  touch-action: manipulation;
}

.styles_cartItemsWrapper__1RDiO ::-webkit-scrollbar {
  display: none;
}

.emptyCart {
  display: flex;
  flex: 1;
  background-color: #f2f2f2;
  align-items: center;
  justify-content: center;

  span {
    font-style: normal;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.3);
  }
}

.cartLine {
  display: block;
  height: 1px;
  width: 100%;
  // margin: 30px 0;
  background: #d9d9d9;
}

.cartCheckout {
  display: flex;
  justify-content: center;
}

.buttonCheckout {
  width: 100%;
  height: 70px;
}

.loaderWrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
}

.loader {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: load 1.1s infinite ease;
  transform: translateZ(0);
}

@keyframes load {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff,
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7),
      2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

@media (min-width: 768px) {
  .cart {
    width: 333px;
  }
  .cartTitle {
    min-height: 60px;

    span {
      font-size: 20px;
      line-height: 30px;
    }
  }
  .emptyCart {
    span {
      font-size: 16px;
      line-height: 30px;
    }
  }
  .cartCheckout {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0 15px;
  }
  .comboCartItemsInfoWrapper {
    width: 91%;
  }
  .cartItemsDelete {
    width: 45px;
  }
}

@media (min-width: 1024px) {
  .cart {
    width: 374px;
  }
}

@media (min-width: 1200px) {
  .cart {
    width: 500px;
  }
  .cartTitle {
    // height: 110px;
    span {
      font-size: 26px;
      line-height: 30px;
    }
  }
  .emptyCart {
    span {
      font-size: 20px;
      line-height: 40px;
    }
  }
  .cartCheckout {
    margin-top: 40px;
    margin-bottom: 50px;
    padding: 0 50px;
  }
}

@media (min-width: 1200px) {
  .cartItemsDelete {
    width: 60px;
  }
}
